<template>
  <router-view></router-view>
</template>
<script setup>
import { provide } from 'vue';

const isDaTeng = window.location.host.includes('da-teng');
const isPoisson = window.location.host.includes('poisson');

if (isDaTeng) {
  document.title = '大腾智能后台管理系统';
  const favicon = document.querySelector('link[rel="icon"]');
  favicon.href = './dateng.png';
}
if(isPoisson){
  document.title = '登录-国产工业数字化协同平台|泊松软件';
  const favicon = document.querySelector('link[rel="icon"]');
  favicon.href = './poisson.png';

}
provide('isDateng', isDaTeng);
provide('isPoisson', isPoisson)
</script>
